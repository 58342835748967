
import { mapWritableState } from 'pinia'
import { slugify } from '@/utilities'
import { useUiStore } from '@/stores/ui'
import { useEventDataLayer } from '@/stores/event_data_layer'
import { useSearchHistoryStore } from '~/stores/search_history'
import { useCustomer } from '~/stores/customer'
import { useCatalog } from '@/stores/catalog'
import { useMarketStore } from '@/stores/market'
import { useDataLayerStore } from '@/stores/dataLayer'

export default {
  name: 'QuickSearch',
  props: {
    setFocus: {
      type: Boolean,
      default: false,
      search_results_query: {
        from_search: 'true',
      },
    },
  },
  setup() {
    const catalogStore = useCatalog()
    const uiStore = useUiStore()
    const searchHistoryStore = useSearchHistoryStore()
    const marketStore = useMarketStore()
    const dataLayerStore = useDataLayerStore()
    return {
      catalogStore,
      uiStore,
      searchHistoryStore,
      marketStore,
      dataLayerStore,
    }
  },
  data() {
    return {
      input: '',
      requestSending: '',
      results: {},
      selected: undefined,
      throttle: null,
      // searchBy: 'products',
      setEnter: false,
      startSearch: false,
      blockedEnter: false,
      once: false,
    }
  },
  computed: {
    ...mapWritableState(useCustomer, ['selectedCoordinate', 'geo']),
    ...mapWritableState(useCatalog, ['quickSearchBy']),
    quickSearchPlaceholder() {
      let placeholder = 'placeholder'

      if (this.quickSearchBy === 'products') {
        placeholder = 'quickSearchPlaceholderMarketplace'
      }

      return this.$i18n.t(placeholder)
    },

    showDropdown() {
      return (
        this.results.popular ||
        this.results.categories ||
        this.results.point_of_sales ||
        this.results.keywords ||
        this.results.products ||
        this.results.mp_categories ||
        this.results.brands ||
        this.results.tags ||
        this.results.merchants ||
        this.requestSending
      )
    },
  },
  watch: {
    input(val, oldVal) {
      if (val.length === 1) {
        this.searchHistoryStore.getSearchHistoryByKey(val)
      }
      if (val.length === 0 && oldVal.length === 1) {
        this.searchHistoryStore.getSearchHistory()
      }
    },
    quickSearchBy() {
      // this.changeSearchType()
      this.searchHistoryStore.showSearchHistoryBlock = false
    },
    startSearch(val) {
      if (this.enterAfterResponseSearch && !val) {
        this.onEnter()
      }
    },
    $route() {
      this.setInputFieldValue()
    },
  },
  created() {
    this.setInputFieldValue()
  },
  mounted() {
    if (this.setFocus) {
      this.$refs.quickSearchInput?.focus()
    }
    if (this.$route.query?.showSearch) {
      setTimeout(() => {
        this.$refs.quickSearchInput?.focus()
      }, 200)
    }
  },
  beforeDestroy() {
    this.searchHistoryStore.showSearchHistoryBlock = false
  },
  methods: {
    onFocus() {
      this.$emit('focus', this.input)
      this.uiStore.resolution === 'md'
        ? (this.uiStore.quickSearchExpanded = true)
        : null
      if (!this.once) {
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'search_field_active',
            tab: this.quickSearchBy,
          })
        }
        this.once = true
      }
    },
    onBlur() {
      this.$emit('blur')
      this.once = false
    },
    deleteSearchQuery(id) {
      this.searchHistoryStore.deleteSearchQuery(id).then(() => {
        setTimeout(() => {
          const val = this.input.trim()
          if (val.length) {
            this.searchHistoryStore.getSearchHistoryByKey(val)
          } else {
            this.searchHistoryStore.getSearchHistory()
          }
        }, 500)
      })
    },
    hideKeybordMobile() {
      this.$refs.quickSearchInput.blur()
    },
    onCloseSearch() {
      if (this.input) {
        this.input = ''
      } else {
        this.uiStore.isSearchVisible = false
      }
    },
    closeDropdown() {
      // this.input = ""; // при клике на пустое место не будет чиститься то, что уже ввели
      this.clearResults()
    },

    onInputChange(e) {
      this.blockedEnter = true
      this.setEnter = e?.key === 'Enter'
      this.input = e?.target?.value.replace(/İ/g, 'I')
      if (!this.input || this.input.trim().length < 2) {
        this.clearResults()
        return
      }
      if (e?.key !== 'Enter') {
        this.startSearch = true
        this.throttleRequest()
      }
      this.searchHistoryStore.showSearchHistoryBlock = false
    },
    changeSearchType() {
      if (!this.input || this.input.trim().length < 2) {
        this.clearResults()
        return
      }
      this.startSearch = true
      this.throttleRequest()
      this.makeSearchRequest()
    },
    clearResults() {
      if (!this.setEnter) {
        this.results = {}
        this.selected = {
          type: '',
          index: undefined,
          obj: null,
        }
        if (this.throttle) {
          clearTimeout(this.throttle)
          this.throttle = null
        }
      }
    },
    throttleRequest() {
      if (this.throttle) {
        clearTimeout(this.throttle)
      }
      this.throttle = setTimeout(() => {
        this.makeSearchRequest()
        this.throttle = null
      }, 500)
    },

    async makeSearchRequest() {
      let resp
      if (!this.input) {
        this.clearResults()
        return
      }
      this.requestSending = true

      this.quickSearchBy === 'companies'
        ? (resp = await this.getQuickSearch())
        : ''

      this.quickSearchBy === 'products'
        ? (resp = await this.getMarketPlaceSearch())
        : ''
      this.results = {
        popular: [],
        categories:
          this.quickSearchBy === 'companies' ? this.createCategories(resp) : [],
        companies: this.createCompanies(resp),
        keywords: this.createKeywords(resp),
        products: this.createProducts(resp),
        mp_categories:
          this.quickSearchBy === 'products' ? this.createCategories(resp) : [],
        brands: resp?.brands,
        tags: resp?.tags?.filter(item => item?.name !== null) || [],
        merchants: this.createMerchants(resp),
        searchBy: this.quickSearchBy,
      }
      this.startSearch = false
      this.requestSending = false
      this.blockedEnter = false
    },
    getQuickSearch() {
      const input = this.input?.toLowerCase()
      const options = {
        page: 1,
        coordinates: this.selectedCoordinate,
        q: input.trim(),
        country_id: +this.geo.countryId,
        city_id: +this.geo.cityId,
      }
      return this.catalogStore.getQuickSearch(options)
    },
    getMarketPlaceSearch() {
      const input = this.input?.includes('İ')
        ? this.input?.replace(/İ/g, 'I').toLowerCase()
        : this.input?.toLowerCase()
      const options = {
        full_text: input.trim(),
        limit: 5,
      }
      return this.catalogStore.getMarketPlaceSearch(options)
    },
    createMerchants(resp) {
      if (!resp) return []
      if (this.quickSearchBy === 'companies') {
        return [
          ...(resp.online_merchants ?? []),
          ...(resp.offline_merchants ?? []),
        ]
      } else if (this.quickSearchBy === 'products') {
        return [...(resp.sellers ?? [])]
      }
      return []
    },
    createCategories(resp) {
      return (resp && resp.categories) || []
    },
    createCompanies(resp) {
      return (resp && resp.point_of_sales) || []
    },
    createKeywords(resp) {
      return (resp && resp.keywords) || []
    },
    createPopular(resp) {
      return resp || []
    },
    createProducts(resp) {
      const defaultProducts = resp?.products || []
      const products = defaultProducts.map((product, index) => {
        return {
          ...product,
          position_number: index + 1,
        }
      })
      return products
    },
    // ----------- Popular ------------
    onClickInputBox() {
      const oldVal = this.input
      const inputValue = this.input.trim()
      if (!this.input && this.quickSearchBy !== 'products') {
        this.showPopular()
      }
      if (inputValue.length >= 2 && oldVal !== this.input) {
        this.makeSearchRequest()
      } else {
        this.searchHistoryStore.search_history = []
        if (inputValue.length === 1) {
          this.searchHistoryStore.getSearchHistoryByKey(inputValue)
          this.searchHistoryStore.showSearchHistoryBlock = true
        } else if (inputValue.length === 0) {
          this.searchHistoryStore.getSearchHistory()
        } else {
          return null
        }
      }
    },
    async showPopular() {
      this.requestSending = true
      const popular = await this.getPopular()
      this.results = {
        popular: this.createPopular(popular),
      }
      this.requestSending = false
    },
    getPopular() {
      return this.catalogStore.getMostPopularPartners()
    },
    onEnter() {
      if (this.blockedEnter) return
      this.search_results_query = {
        from_search: 'true',
        event: 'view_search_results',
        query: this.input,
        tips_type: 'items',
        search_type: 'standard',
      }
      if (this.startSearch) this.enterAfterResponseSearch = true
      if (this.input && this.input.trim().length >= 2 && !this.startSearch) {
        const search_result = this.input?.toLowerCase().replace(/\s/g, '')
        this.enterAfterResponseSearch = false
        const mp_category = this.results?.mp_categories?.find(item => {
          return item?.name?.toLowerCase().replace(/\s/g, '') === search_result
        })
        const brand = this.results?.brands?.find(item => {
          return (
            (item?.name || item?.manufacturer)
              ?.toLowerCase()
              .replace(/\s/g, '') === search_result
          )
        })
        const merchant = this.results?.merchants?.find(item => {
          return item?.name?.toLowerCase().replace(/\s/g, '') === search_result
        })
        const tags = this.results?.tags?.find(item => {
          if (
            item?.mapping_rule === 'filters' ||
            item?.mapping_rule === 'default'
          ) {
            const list_names = item?.names.map(name =>
              name?.toLowerCase().replace(/\s/g, ''),
            )
            return list_names.includes(search_result)
          } else {
            return null
          }
        })
        const product = this.results?.products?.find(item => {
          return (
            item?.name?.toLowerCase().replaceAll(' ', '') === search_result ||
            +item?.id === +search_result
          )
        })
        this.hideKeybordMobile()
        this.setEnter = false
        if (mp_category) {
          this.runMPCategory(mp_category)
          this.sendSearchQuery({
            event_subtype: 'category',
            query: mp_category.name,
            category_id: `${mp_category.id}`,
          })
          this.closeDropdown()
          return
        }
        if (brand) {
          this.runBrand(brand)
          this.sendSearchQuery({
            event_subtype: 'brand',
            query: brand.name,
            tag_id: +brand.id,
          })
          this.closeDropdown()
          return
        }

        if (merchant) {
          this.runMerchants(merchant)
          this.sendSearchQuery({
            event_subtype: 'merchant',
            query: merchant.name,
            marketing_name_id: +merchant.id,
          })
          this.closeDropdown()
          return
        }

        const term = this.input

        if (tags) {
          this.runTag(tags)
          this.sendSearchQuery({
            event_subtype: 'tag',
            query: term,
            tag_id: +tags.id,
          })
          this.closeDropdown()
          return
        }

        if (product) {
          this.dataLayerStore.clearOtherSourceDataToProduct()
          this.dataLayerStore.otherSourceDataToProduct = {
            item_list_index: 0,
            item_list_name: `search${term ? `||${term}` : ''}`,
          }
          this.marketStore.sourceSelectItem = 'search'
          const isSearchByID = /^\d+$/.test(search_result)
          this.runProduct(product)
          this.sendSearchQuery({
            event_subtype: 'product',
            query: isSearchByID ? search_result : product.name,
            product_id: +product.id,
          })
          this.closeDropdown()
          return
        }

        const path = this.localePath({
          name: 'search-term',
          params: {
            term,
          },
          query: {
            ...this.search_results_query,
          },
        })
        this.sendSearchQuery({
          event_subtype: 'search',
          query: term,
        })
        window.location.href = window.location.origin + path
      }
    },

    // ------------------------- select item --------------------
    onSelect({ item, type }) {
      this.search_results_query = {
        from_search: 'true',
        event: 'view_search_tips',
        query: this.input,
        choice: item.name,
        tips_type: 'items',
      }
      const setDataLayerStore = useEventDataLayer()
      setDataLayerStore.SET_DATA_CLICK_SUGGESTS(true)
      // this.setDataLayerOnSelect(item, type)
      if (!item) return // TODO stopProp. & enter in input
      this.catalogStore.setCurrentCategoryId('')
      if (type === 'partner') {
        this.runPartner(item)
      } else if (type === 'pos') {
        this.runPOS(item)
      } else if (type === 'popular') {
        this.runPopular(item)
      } else if (type === 'category') {
        this.runCategory(item)
      } else if (type === 'keyword') {
        this.runKeyword(item)
      } else if (type === 'product') {
        this.runProduct(item)
        this.sendSearchQuery({
          event_subtype: 'product',
          query: item.name,
          product_id: +item.id,
        })
      } else if (type === 'mp_category') {
        this.runMPCategory(item)
        this.sendSearchQuery({
          event_subtype: 'category',
          query: item.name,
          category_id: `${item.id}`,
        })
      } else if (type === 'brand') {
        this.runBrand(item)
        this.sendSearchQuery({
          event_subtype: 'brand',
          query: item.name,
          tag_id: +item.id,
        })
      } else if (type === 'tag') {
        this.runTag(item)
        this.sendSearchQuery({
          event_subtype: item?.mapping_rule === 'fulltext' ? 'search' : 'tag',
          query: item.name,
          tag_id: +item.id,
        })
      } else if (type === 'merchants') {
        this.runMerchants(item)
        this.sendSearchQuery({
          event_subtype: 'merchant',
          query: item.name,
          marketing_name_id: +item.id,
        })
      }

      this.input = ''
      this.setEnter = false
      this.searchHistoryStore.showSearchHistoryBlock = false
      this.closeDropdown()
    },
    // при клике на элемент списка:
    runPopular(item) {
      try {
        this.$router.push({
          path: this.localePath({
            name: 'partner-slug',
            params: {
              slug: `${item.id}-${slugify(item.name)}`,
            },
            query: {
              from_search: 'true',
            },
          }),
        })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },

    runPartner(item) {
      if (this.quickSearchBy === 'companies') {
        let slug = null
        const partner_role = item?.partner_role
        if (
          partner_role === 'partner_role_vendor' ||
          partner_role === 'partner_role_merchant_online'
        ) {
          slug = `${item?.id}-${slugify(item?.name)}`
        } else {
          slug = `${item?.point_of_sale?.marketing_name?.id}-${slugify(
            item?.point_of_sale?.marketing_name?.name,
          )}`
        }
        try {
          this.$router.push({
            path: this.localePath({
              name: 'partner-slug',
              params: {
                slug,
              },
              query: {
                ...this.search_results_query,
              },
            }),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      } else {
        try {
          this.$router.push({
            path: this.localePath({
              name: 'partner-slug',
              params: {
                slug: `${
                  item?.marketing_name && item?.marketing_name?.id
                }-${slugify(item?.marketing_name?.name)}`,
              },
              query: {
                from_search: 'true',
              },
            }),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      }
    },
    runPOS(item) {
      const id = item.id
      const name = item?.marketing_name?.name
      const slug = item?.seo?.slug || slugify(name)
      try {
        this.$router.push({
          path: this.localePath({
            name: 'pos-slug',
            params: { slug: `${id}-${slug}` },
            query: {
              ...this.search_results_query,
            },
          }),
        })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runCategory(item) {
      const id = item.id
      const name = item[`name_${this.$i18n.locale}`]
      const path = this.localePath({
        name: 'category-slug',
        params: { slug: `${id}-${slugify(name)}` },
        query: {
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runKeyword(keyword) {
      const path = this.localePath({
        name: 'keyword-slug',
        params: { slug: keyword?.keywords.replace(new RegExp(' ', 'g'), '_') },
        query: {
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runProduct(item) {
      const resultsQuery = { ...this.search_results_query }
      if (resultsQuery?.choice) delete resultsQuery?.choice
      const path = this.localePath({
        name: 'product-slug',
        params: { slug: `${item.id}-${item?.slugged_name}` },
        query: {
          ...resultsQuery,
          position_number: item.position_number,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runMPCategory(item) {
      const id = item.id
      const name = item?.slugged_name
      const path = this.localePath({
        name: 'categories-slug',
        params: { slug: `${id}-${name}` },
        query: {
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runBrand(item) {
      const path = this.localePath({
        name: 'brands-brand',
        params: {
          brand: item?.name || item?.manufacturer,
        },
        query: {
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runTag(item) {
      const has_correct_name = item?.names.find(tag_name => {
        if (tag_name) {
          return (
            tag_name.toLowerCase().trim() === item.name.toLowerCase().trim()
          )
        } else {
          return false
        }
      })
      if (
        (has_correct_name && item.mapping_rule === 'filters') ||
        item.mapping_rule === 'default'
      ) {
        const path = this.localePath({
          name: 'tags-tag',
          params: {
            tag: item.slugged_name,
          },
          query: {
            ...this.search_results_query,
            tag_name: item.name,
          },
        })
        try {
          this.$router.push({ path })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      } else {
        const term = item.name
        const path = this.localePath({
          name: 'search-term',
          params: {
            term,
          },
          query: {
            ...this.search_results_query,
          },
        })
        window.location.href = window.location.origin + path
      }
    },
    runMerchants(item) {
      const path = this.localePath({
        name: 'merchant-merchant',
        params: {
          merchant: `${item.id}-${item.name.replace(/ /g, '-')}`,
        },
        query: {
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    runTagCategories(item) {
      const id = item?.category?.id
      const name = item?.category[`slugged_name_${this.$i18n.locale}`]
      const query = {}
      if (item.brand) {
        query['q[manufacturer_in]'] = item.brand
      } else if (item.master_field) {
        query[
          'q[facets]'
        ] = `${item.master_field.id}:${item.master_field.value}`
      }
      query.from_search = true
      const path = this.localePath({
        name: 'categories-slug',
        params: {
          slug: `${id}-${name}`,
          tag: item.slugged_name,
          tag_name: item.name,
        },
        query: {
          ...query,
          ...this.search_results_query,
        },
      })
      try {
        this.$router.push({ path })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    async sendSearchQuery(data) {
      const payload = {
        event_type: 'search',
        event_subtype: data.event_subtype,
        query: data.query,
        product_id: data.product_id,
        marketing_name_id: data.marketing_name_id,
        tag_id: data.tag_id,
        category_id: data.category_id,
      }
      await this.searchHistoryStore.sendSearchQuery(payload)
    },
    setInputFieldValue() {
      if (this.$route?.name?.includes('brands'))
        this.input = this.$route?.params?.brand || ''

      if (
        this.$route?.name?.includes('merchant') ||
        this.$route?.name?.includes('categories')
      )
        this.input = this.$route?.query?.choice || ''

      if (this.$route?.name?.includes('tags'))
        this.input = this.$route?.query?.tag_name || ''

      if (this.$route?.name?.includes('search'))
        this.input = this.$route?.params?.term || ''
    },
  },
}
